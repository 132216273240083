import React from 'react';
import ChatBot from '../components/chat/ChatBot';
import { useAuth } from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';

const ChatPage = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="chat-page">
      <h1>Chat con FISCALia</h1>
      <ChatBot />
    </div>
  );
};

export default ChatPage;