import React from 'react';
import { FaEdit, FaTrash, FaFileAlt } from 'react-icons/fa';
import '../../styles/UserManagement.css';

const UserTable = ({ users, onEdit, onDelete, onViewDocuments }) => {
  return (
    <table className="user-table">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Cognome</th>
          <th>Email</th>
          <th>Ruolo</th>
          <th>Validato</th>
          <th>Attivo</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.id || user._id}>
            <td>{user.nome}</td>
            <td>{user.cognome}</td>
            <td>{user.email}</td>
            <td>{user.role}</td>
            <td>{user.isValidated ? 'Sì' : 'No'}</td>
            <td>{user.isActive ? 'Sì' : 'No'}</td>
            <td>
              <button onClick={() => onEdit(user)} className="action-button edit" title="Modifica">
                <FaEdit />
                <span className="action-text">Modifica</span>
              </button>
              <button onClick={() => onDelete(user.id || user._id)} className="action-button delete" title="Elimina">
                <FaTrash />
                <span className="action-text">Elimina</span>
              </button>
              <button onClick={() => onViewDocuments(user.id || user._id)} className="action-button view-docs" title="Documenti">
                <FaFileAlt />
                <span className="action-text">Documenti</span>
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserTable;