import React, { useState, useRef, useEffect, useCallback } from 'react';
import Message from './Message';
import { useAuth } from '../../hooks/useAuth';
import { startConversation, closeConversation } from '../../services/api';
import '../../styles/ChatBot.css';

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [error, setError] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { user } = useAuth();
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const isInitialMount = useRef(true);
  //const [messageCache, setMessageCache] = useState({});

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleCloseConversation = useCallback(async () => {
    if (conversationId) {
      try {
        await closeConversation(conversationId);
        setConversationId(null);
        setMessages([]);
        //setMessageCache({});
      } catch (error) {
        console.error('Errore durante la chiusura della conversazione:', error);
      }
    }
  }, [conversationId]);

  const initConversation = useCallback(async () => {
    if (conversationId) return;
    
    try {
      setIsLoading(true);
      const response = await startConversation();
      if (response.conversationId) {
        setConversationId(response.conversationId);
        setError(null);
      } else if (response.activeConversationId) {
        setConversationId(response.activeConversationId);
        setError(null);
      }
    } catch (err) {
      console.error('Errore durante l\'inizializzazione della conversazione:', err);
      setError('Impossibile avviare la conversazione. Riprova più tardi.');
    } finally {
      setIsLoading(false);
    }
  }, [conversationId]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      initConversation();
    }

    return () => {
      if (conversationId) {
        handleCloseConversation();
      }
    };
  }, [initConversation, handleCloseConversation, conversationId]);
  

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (conversationId) {
        event.preventDefault();
        event.returnValue = '';
        handleCloseConversation();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [conversationId, handleCloseConversation]);

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleSend = async () => {
    if ((input.trim() === '' && selectedFiles.length === 0) || !conversationId) return;

    const newUserMessage = { 
      text: input, 
      sender: 'user',
      files: selectedFiles.map(file => file.name)
    };
    setMessages(prevMessages => [...prevMessages, newUserMessage]);
    setInput('');
    setIsLoading(true);

    try {
      if (selectedFiles.length <= 1) {
        // Existing logic for text messages or single file
        const formData = new FormData();
        formData.append('message', input);
        formData.append('conversationId', conversationId);
        if (selectedFiles.length === 1) {
          formData.append('documents', selectedFiles[0]);
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${user.token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        let botMessage = { text: '', sender: 'bot' };
        setMessages(prevMessages => [...prevMessages, botMessage]);

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          
          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.split('\n');
          
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = JSON.parse(line.slice(6));
              if (data.content === '[DONE]') {
                setIsLoading(false);
                //setMessageCache(prev => ({...prev, [input]: botMessage.text}));
              } else {
                botMessage.text += data.content;
                setMessages(prevMessages => 
                  prevMessages.map((msg, index) => 
                    index === prevMessages.length - 1 ? botMessage : msg
                  )
                );
              }
            }
          }
        }
      } else {
        // New logic for multiple files
        const sendFileRequests = selectedFiles.map(async (file, index) => {
          const formData = new FormData();
          formData.append('message', index === 0 ? input : ''); // Only send text with the first file
          formData.append('conversationId', conversationId);
          formData.append('documents', file);

          return fetch(`${process.env.REACT_APP_API_URL}/api/chat`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${user.token}`,
            },
            body: formData,
          });
        });

        const responses = await Promise.all(sendFileRequests);

        for (const response of responses) {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();

          let botMessage = { text: '', sender: 'bot' };
          setMessages(prevMessages => [...prevMessages, botMessage]);

          while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            
            const chunk = decoder.decode(value, { stream: true });
            const lines = chunk.split('\n');
            
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const data = JSON.parse(line.slice(6));
                if (data.content === '[DONE]') {
                  setIsLoading(false);
                } else {
                  botMessage.text += data.content;
                  setMessages(prevMessages => 
                    prevMessages.map((msg, index) => 
                      index === prevMessages.length - 1 ? botMessage : msg
                    )
                  );
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [...prevMessages, { text: "Mi dispiace, si è verificato un errore. Riprova più tardi.", sender: 'bot' }]);
      setIsLoading(false);
    }

    setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleVoiceInput = () => {
    setIsListening(true);

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.lang = 'it-IT';
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;

      recognition.start();

      recognition.onresult = (event) => {
        const speechResult = event.results[0][0].transcript;
        setInput(speechResult);
        setIsListening(false);
      };

      recognition.onerror = (event) => {
        console.error('Errore nel riconoscimento vocale', event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        setIsListening(false);
      };
    } else {
      console.log('Il riconoscimento vocale non è supportato');
      setIsListening(false);
    }
  };

  return (
    <div className="chatbot-container">
      <div className="chat-header">
        <h2>FISCALia Assistant</h2>
      </div>
      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <>
          <div className="chat-messages">
            {messages.map((message, index) => (
              <Message key={index} message={message} />
            ))}
            {isLoading && <div className="loading">FISCALia sta scrivendo...</div>}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input">
            <button 
              onClick={handleVoiceInput} 
              className={`voice-input-btn ${isListening ? 'listening' : ''}`}
            >
              🎤
            </button>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
              placeholder="Scrivi un messaggio..."
              disabled={!conversationId || isLoading}
            />
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <button onClick={() => fileInputRef.current.click()} disabled={!conversationId || isLoading}>
              📎
            </button>
            <button onClick={handleSend} disabled={!conversationId || isLoading}>Invia</button>
          </div>
          {selectedFiles.length > 0 && (
            <div className="selected-files">
              File selezionati: {selectedFiles.map(file => file.name).join(', ')}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatBot;