import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import UserManager from '../components/admin/UserManager';
import DocumentManager from '../components/admin/DocumentManager';
import CompositionManager from '../components/admin/CompositionManager';
import Retrieval from '../components/admin/Retrieval';
import PromptManager from '../components/admin/PromptManager';
import ChunkManager from '../components/admin/ChunkManager';
import KnowlegeBaseManager from '../components/admin/KnowlegeBaseManager';

const AdminPage = () => {
  const { user } = useAuth();

  if (!user || user.role !== 'admin') {
    return <Navigate to="/" />;
  }

  return (
    <div className="page-container admin-page">
      <h1 className="page-title">Pannello di Amministrazione</h1>
      <Routes>
        <Route path="users" element={<UserManager />} />        
        <Route path="documents/user/:userId" element={<DocumentManager />} />
        <Route path="documents/*" element={<DocumentManager />} />
        <Route path="compositions/*" element={<CompositionManager />} />
        <Route path="retrieval" element={<Retrieval />} />
        <Route path="knowlegebase" element={<KnowlegeBaseManager />} />
        <Route path="chunks/*" element={<ChunkManager />} />
        <Route path="prompts" element={<PromptManager />} />
        <Route path="*" element={<Navigate to="users" replace />} />
      </Routes>
    </div>
  );
};

export default AdminPage;