import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { FaUser, FaCog, FaSignOutAlt, FaCaretDown, FaComments } from 'react-icons/fa';
import { FISCALiaFullLogo } from './logo';
import '../../styles/Header.css';

const Header = () => {
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleChatClick = () => {
    navigate('/chat');
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <FISCALiaFullLogo />
        </Link>
      </div>
      <nav>
        {user ? (
          <>
            <button onClick={handleChatClick} className="chat-button">
              <FaComments className="chat-icon" />
              Chatta ora
            </button>
            <div className="user-menu-container">
              <button onClick={toggleMenu} className="user-menu-trigger">
                <FaUser className="user-icon" />
                {user.nome}
                <FaCaretDown className="caret-icon" />
              </button>
              {isMenuOpen && (
                <div className="user-menu">
                  <div className="user-info">
                    <p>{`${user.nome} ${user.cognome}`}</p>
                    <p>{user.email}</p>
                    <p>Ruolo: {user.role}</p>
                  </div>                 
                  <Link to={user.role === 'admin' ? "/admin" : "/user"} onClick={toggleMenu}>
                    <FaUser /> {user.role === 'admin' ? 'Dashboard Admin' : 'Area Utente'}
                  </Link>
                  <Link to="/settings" onClick={toggleMenu}>
                    <FaCog /> Impostazioni
                  </Link>
                  <button onClick={() => { logout(); toggleMenu(); }}>
                    <FaSignOutAlt /> Logout
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <Link to="/auth" className="auth-link">Accedi / Registrati</Link>
        )}
      </nav>
    </header>
  );
};

export default Header;