import React, { useState, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { FaChevronDown, FaChevronRight, FaTrash, FaPlusCircle, FaEdit, FaSave, FaExclamationTriangle } from 'react-icons/fa';

const StructuredDataItem = ({ itemKey, itemValue, path, onValueChange, onDeleteField, onAddField }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedKey, setEditedKey] = useState(itemKey);
  const [editedValue, setEditedValue] = useState(itemValue);
  const isObject = typeof itemValue === 'object' && itemValue !== null;

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleSave = () => {
    onValueChange(path, editedValue, editedKey, itemKey);
    setIsEditing(false);
  };

  if (isObject) {
    return (
      <div className="structured-data-item">
        <div className="structured-data-header">
          <div onClick={toggleExpand}>
            {isExpanded ? <FaChevronDown className="structured-data-icon" /> : <FaChevronRight className="structured-data-icon" />}
            <h4>{itemKey}</h4>
          </div>
          <button className="action-button delete" onClick={() => onDeleteField(path)} title="Elimina">
            <FaTrash />
          </button>
        </div>
        <div className={`structured-data-content ${isExpanded ? 'expanded' : ''}`}>
          {Object.entries(itemValue).map(([key, value]) => (
            <StructuredDataItem
              key={`${path}.${key}`}
              itemKey={key}
              itemValue={value}
              path={`${path}.${key}`}
              onValueChange={onValueChange}
              onDeleteField={onDeleteField}
              onAddField={onAddField}
            />
          ))}
          <div className="structured-data-actions">
            <button className="action-button" onClick={() => onAddField(path)} title="Aggiungi Campo">
              <FaPlusCircle />
            </button>
            <button className="action-button" onClick={() => onAddField(path, true)} title="Aggiungi Oggetto">
              <FaPlusCircle />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="structured-data-field">
      {isEditing ? (
        <>
          <input
            className="structured-data-input"
            type="text"
            value={editedKey}
            onChange={(e) => setEditedKey(e.target.value)}
          />
          <input
            className="structured-data-input"
            type="text"
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
          />
          <button className="action-button" onClick={handleSave} title="Salva">
            <FaSave />
          </button>
        </>
      ) : (
        <>
          <span>{itemKey}: {itemValue}</span>
          <button className="action-button" onClick={() => setIsEditing(true)} title="Modifica">
            <FaEdit />
          </button>
        </>
      )}
      <button className="action-button delete" onClick={() => onDeleteField(path)} title="Elimina">
        <FaTrash />
      </button>
    </div>
  );
};

const StructuredDataEditor = forwardRef(({ structuredData, onStructuredDataChange }, ref) => {
  const [localWarnings, setLocalWarnings] = useState(structuredData.warnings || []);
  const localWarningsRef = useRef(localWarnings);

  useEffect(() => {
    setLocalWarnings(structuredData.warnings || []);
  }, [structuredData.warnings]);

  useEffect(() => {
    localWarningsRef.current = localWarnings;
  }, [localWarnings]);

  const handleValueChange = useCallback((path, value, newKey, oldKey) => {
    const newData = JSON.parse(JSON.stringify(structuredData));
    let current = newData.values;
    const keys = path.split('.');
    const parentKeys = keys.slice(0, -1);
    const lastKey = keys[keys.length - 1];

    for (const key of parentKeys) {
      if (!current[key]) current[key] = {};
      current = current[key];
    }

    if (newKey !== oldKey) {
      delete current[oldKey];
      current[newKey] = value;
    } else {
      current[lastKey] = value;
    }

    onStructuredDataChange(newData);
  }, [structuredData, onStructuredDataChange]);

  const handleAddField = useCallback((path, isObject = false) => {
    const newData = JSON.parse(JSON.stringify(structuredData));
    let current = newData.values;
    const keys = path ? path.split('.') : [];
    for (const key of keys) {
      if (!current[key]) current[key] = {};
      current = current[key];
    }
    const newKey = `nuovo_campo_${Object.keys(current).length + 1}`;
    current[newKey] = isObject ? {} : '';
    onStructuredDataChange(newData);
  }, [structuredData, onStructuredDataChange]);

  const handleDeleteField = useCallback((path) => {
    const newData = JSON.parse(JSON.stringify(structuredData));
    let current = newData.values;
    const keys = path.split('.');
    const lastKey = keys.pop();
    for (const key of keys) {
      if (!current[key]) return;
      current = current[key];
    }
    delete current[lastKey];
    onStructuredDataChange(newData);
  }, [structuredData, onStructuredDataChange]);

  const handleWarningChange = useCallback((index, newWarning) => {
    setLocalWarnings(prevWarnings => {
      const newWarnings = [...prevWarnings];
      newWarnings[index] = newWarning;
      return newWarnings;
    });
  }, []);

  const handleDeleteWarning = useCallback((index) => {
    setLocalWarnings(prevWarnings => prevWarnings.filter((_, i) => i !== index));
  }, []);

  const handleAddWarning = useCallback(() => {
    setLocalWarnings(prevWarnings => [...prevWarnings, '']);
  }, []);

  // Esponiamo una funzione per ottenere le avvertenze locali
  useImperativeHandle(ref, () => ({
    getLocalWarnings: () => localWarningsRef.current
  }));

  return (
    <div className="structured-data-editor">
      <h3>Dati Strutturati</h3>
      <div className="structured-data">
        {Object.entries(structuredData.values).map(([key, value]) => (
          <StructuredDataItem
            key={key}
            itemKey={key}
            itemValue={value}
            path={key}
            onValueChange={handleValueChange}
            onDeleteField={handleDeleteField}
            onAddField={handleAddField}
          />
        ))}
        <button className="add-object-button" onClick={() => handleAddField('', true)}>Aggiungi Oggetto Principale</button>
      </div>
      <div className="warnings-section">
        <h3>Avvertenze</h3>
        {localWarnings.map((warning, index) => (
          <div key={index} className="warning-item">
            <input
              type="text"
              value={warning}
              onChange={(e) => handleWarningChange(index, e.target.value)}
            />
            <button className="action-button delete" onClick={() => handleDeleteWarning(index)}>
              <FaTrash />
            </button>
          </div>
        ))}
        <button className="add-warning-button" onClick={handleAddWarning}>
          <FaExclamationTriangle /> Aggiungi Avvertenza
        </button>
      </div>
    </div>
  );
});

export default StructuredDataEditor;