import React from 'react';
import ReactMarkdown from 'react-markdown';

const Message = ({ message }) => {
  return (
    <div className={`message ${message.sender}`}>
      <div className="message-content">
        <ReactMarkdown>{message.text}</ReactMarkdown>
        {message.files && message.files.length > 0 && (
          <div className="file-attachments">
            <p>File allegati:</p>
            <ul>
              {message.files.map((file, index) => (
                <li key={index}>{file}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {message.sender === 'bot' && (
        <div className="message-actions">
          <button className="action-button copy" onClick={() => navigator.clipboard.writeText(message.text)}>
            📋
          </button>
        </div>
      )}
    </div>
  );
};

export default Message;