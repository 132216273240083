import React, { useState } from 'react';
import { FaDownload, FaSearchPlus, FaSearchMinus, FaPrint } from 'react-icons/fa';

const ImageViewer = ({ src, fileName }) => {
  const [zoom, setZoom] = useState(1);

  const handleZoomIn = () => setZoom(prev => Math.min(prev + 0.1, 3));
  const handleZoomOut = () => setZoom(prev => Math.max(prev - 0.1, 0.1));
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`<img src="${src}" style="max-width:100%;" />`);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div className="image-viewer">
      <div className="image-controls">
        <button onClick={handleZoomOut} className="control-button"><FaSearchMinus /></button>
        <button onClick={handleZoomIn} className="control-button"><FaSearchPlus /></button>
        <button onClick={handlePrint} className="control-button"><FaPrint /></button>
        <a href={src} download={fileName} className="control-button">
          <FaDownload /> Scarica
        </a>
      </div>
      <div className="image-container">
        <img 
          src={src} 
          alt={fileName} 
          style={{ transform: `scale(${zoom})` }}
        />
      </div>
    </div>
  );
};

export default ImageViewer;