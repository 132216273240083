import React from 'react';
import { FaTrash, FaEdit, FaDownload } from 'react-icons/fa';
import '../../styles/AdminDashboard.css';
import '../../styles/CompositionManagement.css';

const CompositionTable = ({ compositions, onDelete, onDownload, onEdit }) => {
  return (
    <table className="admin-table">
      <thead>
        <tr>
          <th>ID Composizione</th>
          <th>Tipo</th>
          <th>Utente</th>
          <th>Data Creazione</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        {compositions.map((composition) => (
          <tr key={composition._id}>
            <td>{composition._id}</td>
            <td>{composition.compositionType}</td>
            <td>{composition.userName}</td>
            <td>{new Date(composition.createdAt).toLocaleDateString()}</td>
            <td>
              <button 
                onClick={() => onEdit(composition)} 
                className="action-button edit"
                title="Visualizza e Modifica"
              >
                <FaEdit />
                <span className="action-text">Visualizza/Modifica</span>
              </button>
              <button 
                onClick={() => onDownload(composition)} 
                className="action-button download"
                title="Scarica Composizione"
              >
                <FaDownload />
                <span className="action-text">Scarica</span>
              </button>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(composition.userId, composition._id);
                }} 
                className="action-button delete"
                title="Elimina Composizione"
              >
                <FaTrash />
                <span className="action-text">Elimina</span>
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CompositionTable;