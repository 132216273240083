import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { FaComments } from 'react-icons/fa';
import '../styles/Home.css';

const HomePage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleChatClick = () => {
    navigate('/auth', { state: { from: '/chat' } });
  };

  return (
    <div className="home-container">
      <h1>Benvenuti in FISCALia</h1>
      <p className="description">
        Il nostro chatbot è progettato per assistere professionisti in ambito fiscale, regolatorio e previdenziale. 
        Offre risposte rapide e accurate alle tue domande, aiutandoti a navigare le complessità del settore.
      </p>
      <div className="features">
        <div className="feature">
          <h3>Risposte immediate a domande fiscali complesse</h3>
        </div>
        <div className="feature">
          <h3>Aggiornamenti sulle ultime normative e regolamenti</h3>
        </div>
        <div className="feature">
          <h3>Assistenza nella compilazione di documenti fiscali</h3>
        </div>
        <div className="feature">
          <h3>Consigli personalizzati basati sulla tua situazione specifica</h3>
        </div>
      </div>
      {!user && (
        <button onClick={handleChatClick} className="cta-button">
          <FaComments className="chat-icon" />
          Accedi e chatta
        </button>
      )}
    </div>
  );
};

export default HomePage;