import React, { useState, useEffect, useCallback } from 'react';
import { FaSearch, FaPlus, FaFilter } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { 
  listKnowledgeBaseDocuments,
  getChunksByDocumentId,
  addChunk,
  getChunkById
} from '../../services/api';
import ChunkTable from './ChunkTable';
import ChunkDetailView from './ChunkDetailView';
import '../../styles/AdminDashboard.css';
import '../../styles/KnowledgeBaseManagement.css';

const ChunkManager = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [chunks, setChunks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [chunkIdSearch, setChunkIdSearch] = useState('');
  const [selectedChunkId, setSelectedChunkId] = useState(null);
  const [newChunkContent, setNewChunkContent] = useState('');

  useEffect(() => {
    fetchDocuments();
    fetchChunks();
  }, []);

  // Funzione di debounce
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Funzione di ricerca con debounce
  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      if (searchTerm) {
        handleSearch(searchTerm);
      } else {
        fetchChunks(selectedDocument?._id, currentPage);
      }
    }, 300),
    [selectedDocument, currentPage]
  );

  useEffect(() => {
    debouncedSearch(chunkIdSearch);
  }, [chunkIdSearch, debouncedSearch]);

  const fetchDocuments = async () => {
    try {
      const response = await listKnowledgeBaseDocuments();
      setDocuments(response);
    } catch (error) {
      toast.error('Errore nel recupero dei documenti');
      console.error('Errore nel recupero dei documenti:', error);
    }
  };

  const fetchChunks = async (documentId = null, page = 1) => {
    setIsLoading(true);
    try {
      const response = await getChunksByDocumentId(documentId, page);
      setChunks(response.chunks);
      setCurrentPage(response.currentPage);
      setTotalPages(response.totalPages);
    } catch (error) {
      toast.error('Errore nel recupero dei chunk');
      console.error('Errore nel recupero dei chunk:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDocumentSelect = async (event) => {
    const documentId = event.target.value;
    if (documentId === "") {
      setSelectedDocument(null);
      fetchChunks(null, 1);
    } else {
      const document = documents.find(doc => doc._id === documentId);
      setSelectedDocument(document);
      fetchChunks(documentId, 1);
    }
  };

  const handleSearch = async (searchTerm) => {
    if (!searchTerm) return;
    
    setIsLoading(true);
    try {
      const chunk = await getChunkById(searchTerm);
      setChunks([chunk]);
      setCurrentPage(1);
      setTotalPages(1);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error('Chunk non trovato');
        setChunks([]);
      } else {
        console.error('Errore nella ricerca del chunk:', error);
        toast.error('Errore nella ricerca del chunk');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddChunk = async () => {
    if (!selectedDocument) {
      toast.error('Seleziona un documento prima di aggiungere un chunk');
      return;
    }
    if (!newChunkContent.trim()) {
      toast.error('Il contenuto del chunk non può essere vuoto');
      return;
    }
    try {
      await addChunk(newChunkContent, selectedDocument._id);
      toast.success('Chunk aggiunto con successo');
      setNewChunkContent('');
      fetchChunks(selectedDocument._id, currentPage);
    } catch (error) {
      toast.error('Errore nell\'aggiunta del chunk');
      console.error('Errore nell\'aggiunta del chunk:', error);
    }
  };

  const handleEditChunk = (chunkId) => {
    setSelectedChunkId(chunkId);
  };

  const handleCloseDetailView = () => {
    setSelectedChunkId(null);
    fetchChunks(selectedDocument?._id, currentPage);
  };

  const handlePageChange = (newPage) => {
    fetchChunks(selectedDocument?._id, newPage);
  };

  return (
    <div className={`admin-dashboard ${selectedChunkId ? 'chunk-detail-view-active' : ''}`}>
      <h2 className="content-title">Gestione Chunks</h2>
      {isLoading ? (
        <p>Caricamento...</p>
      ) : (
        <div className="chunk-list">
          {!selectedChunkId && (
            <>
              <div className="chunk-actions">
                <div className="document-filter">
                  <FaFilter />
                  <select onChange={handleDocumentSelect} value={selectedDocument?._id || ""} className="document-select">
                    <option value="">Tutti i documenti</option>
                    {documents.map((doc) => (
                      <option key={doc._id} value={doc._id}>{doc.originalName}</option>
                    ))}
                  </select>
                </div>
                <div className="chunk-search">
                  <FaSearch />
                  <input
                    type="text"
                    placeholder="Cerca per ID chunk"
                    value={chunkIdSearch}
                    onChange={(e) => setChunkIdSearch(e.target.value)}
                    className="search-input"
                  />
                </div>
              </div>
              {selectedDocument && (
                <div className="add-chunk-form">
                  <input
                    type="text"
                    value={newChunkContent}
                    onChange={(e) => setNewChunkContent(e.target.value)}
                    placeholder="Contenuto del nuovo chunk"
                    className="chunk-input"
                  />
                  <button onClick={handleAddChunk} className="action-button">
                    <FaPlus />
                  </button>
                </div>
              )}
            </>
          )}
          {selectedChunkId ? (
            <ChunkDetailView
              chunkId={selectedChunkId}
              onClose={handleCloseDetailView}
            />
          ) : (
            <ChunkTable
              chunks={chunks}
              onEdit={handleEditChunk}
              onDelete={() => {}} // Implement delete functionality if needed
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ChunkManager;