import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import '../styles/Auth.css';

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const { login, register } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      if (isLogin) {
        const userData = await login(formData.email, formData.password);
        if (userData && userData.role) {
          if (userData.role === 'admin') {
            navigate('/admin/users');
          } else {
            navigate('/user/documents');
          }
        } else {
          // Se userData o userData.role non sono definiti, reindirizza a una pagina di default
          navigate('/');
        }
      } else {
        await register(formData);
        navigate('/user/documents');
      }
    } catch (err) {
      setError(err.message || 'Si è verificato un errore. Riprova più tardi.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h2>{isLogin ? 'Accedi' : 'Registrati'}</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          {!isLogin && (
            <>
              <input
                type="text"
                name="nome"
                placeholder="Nome"
                value={formData.nome}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="cognome"
                placeholder="Cognome"
                value={formData.cognome}
                onChange={handleChange}
                required
              />
            </>
          )}
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <button type="submit">{isLogin ? 'Accedi' : 'Registrati'}</button>
        </form>
        <p>
          {isLogin ? 'Non hai un account?' : 'Hai già un account?'}
          <button className="switch-auth" onClick={() => setIsLogin(!isLogin)}>
            {isLogin ? 'Registrati' : 'Accedi'}
          </button>
        </p>
      </div>
    </div>
  );
};

export default AuthPage;