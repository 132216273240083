import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

const PromptTable = ({ prompts, onEdit, onDelete }) => {
  const truncateContent = (content, maxLength = 50) => {
    if (content.length <= maxLength) return content;
    return content.substr(0, maxLength) + '...';
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('it-IT', options);
  };

  if (!prompts || prompts.length === 0) {
    return <p>Nessun prompt disponibile.</p>;
  }

  return (
    <table className="admin-table">
      <thead>
        <tr>
          <th>Contenuto</th>
          <th>Tipo</th>
          <th>Data Creazione</th>
          <th>Ultima Modifica</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        {prompts.map((prompt) => (
          <tr key={prompt._id}>
            <td>{truncateContent(prompt.content)}</td>
            <td>{prompt.type}</td>
            <td>{formatDate(prompt.createdAt)}</td>
            <td>{formatDate(prompt.updatedAt)}</td>
            <td>
              <button onClick={() => onEdit(prompt)} className="action-button edit" title="Modifica">
                <FaEdit />
                <span className="action-text">Modifica</span>
              </button>
              <button onClick={() => onDelete(prompt._id)} className="action-button delete" title="Elimina">
                <FaTrash />
                <span className="action-text">Elimina</span>
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PromptTable;