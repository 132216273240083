import React, { createContext, useState, useEffect } from 'react';
import { loginUser, logoutUser, registerUser } from '../services/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    try {
      const response = await loginUser(email, password);      
      if (response && response.userId) {
        const userData = {
          id: response.userId,
          nome: response.nome,
          cognome: response.cognome,
          email: response.email,
          role: response.role,
          token: response.token,
          expiresAt: response.expiresAt
        };
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        return userData;
      } else {
        throw new Error('Dati utente non validi');
      }
    } catch (error) {
      console.error('Errore durante il login:', error);
      throw error;
    }
  };


  const logout = async () => {
    try {
      if (user && user.token) {
        await logoutUser(user.token);
      }
      setUser(null);
      localStorage.removeItem('user');
    } catch (error) {
      console.error('Errore durante il logout:', error);
    }
  };

  const register = async (userData, onRegisterSuccess) => {
    const response = await registerUser(userData);
    const newUserData = {
      id: response.userId,
      nome: userData.nome,
      cognome: userData.cognome,
      email: userData.email,
      role: response.role,
      token: response.token
    };
    setUser(newUserData);
    localStorage.setItem('user', JSON.stringify(newUserData));
    if (onRegisterSuccess) {
      onRegisterSuccess(newUserData);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, register, loading }}>
      {children}
    </AuthContext.Provider>
  );
};