import React, { useState, useEffect, useCallback } from 'react';
import { getDocuments, uploadDocument, deleteDocument, analyzeDocument } from '../../services/api';
import { FaUpload, FaTrash, FaSearch } from 'react-icons/fa';
import '../../styles/UserManagement.css';

const UserDocuments = ({ userId, onBack }) => {
  const [documents, setDocuments] = useState([]);
  const [file, setFile] = useState(null);

  const fetchDocuments = useCallback(async () => {
    try {
      const docs = await getDocuments(userId);
      setDocuments(docs);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }, [userId]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) return;

    try {
      await uploadDocument(userId, file);
      fetchDocuments();
      setFile(null);
    } catch (error) {
      console.error('Error uploading document:', error);
    }
  };

  const handleDelete = async (documentId) => {
    try {
      await deleteDocument(documentId);
      fetchDocuments();
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleAnalyze = async (documentId) => {
    try {
      await analyzeDocument(documentId);
      fetchDocuments();
    } catch (error) {
      console.error('Error analyzing document:', error);
    }
  };

  return (
    <div className="user-documents">
      <h3>Documenti dell'utente</h3>
      <form onSubmit={handleUpload} className="upload-form">
        <input 
          type="file" 
          onChange={(e) => setFile(e.target.files[0])} 
          className="file-input"
        />
        <button type="submit" className="upload-button">
          <FaUpload /> Carica Documento
        </button>
      </form>
      <ul className="document-list">
        {documents.map((doc) => (
          <li key={doc.id} className="document-item">
            <span>{doc.name}</span>
            <div className="document-actions">
              <button onClick={() => handleAnalyze(doc.id)} className="analyze-button">
                <FaSearch /> Analizza
              </button>
              <button onClick={() => handleDelete(doc.id)} className="delete-button">
                <FaTrash /> Elimina
              </button>
            </div>
          </li>
        ))}
      </ul>
      <button onClick={onBack} className="back-button">Torna alla lista utenti</button>
    </div>
  );
};

export default UserDocuments;