import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useNavigate, useLocation, useParams } from 'react-router-dom';
import { getDocuments, uploadDocument, deleteDocument, analyzeDocument, summarizeDocument, searchUsers, getUserById } from '../../services/api';
import DocumentTable from './DocumentTable';
import DocumentDetailView from './DocumentDetailView';
import { FaUpload, FaSearch, FaUser, FaArrowLeft, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import '../../styles/AdminDashboard.css';
import '../../styles/DocumentManagement.css'

const DocumentManager = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [showUserSearch, setShowUserSearch] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams();

  const fetchDocuments = useCallback(async (userIdToFetch = null, page = 1) => {
    try {
      const response = await getDocuments(userIdToFetch || userId || null, page);      
      setDocuments(response.documents);
      setTotalPages(response.totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error('Errore nel recupero dei documenti');
      console.error('Errore nel recupero dei documenti:', error);
    }
  }, [userId]);
  
  useEffect(() => {
    const initializeState = async () => {
      if (userId) {
        try {
          const user = await getUserById(userId);
          setSelectedUser(user);
          fetchDocuments(userId, 1);
        } catch (error) {
          console.error('Errore nel recupero dei dettagli dell\'utente:', error);
          toast.error('Errore nel recupero dei dettagli dell\'utente');
          fetchDocuments(null, 1);
        }
      } else if (location.state && location.state.selectedUserId) {
        try {
          const user = await getUserById(location.state.selectedUserId);
          setSelectedUser(user);
          fetchDocuments(user._id, 1);
        } catch (error) {
          console.error('Errore nel recupero dei dettagli dell\'utente:', error);
          toast.error('Errore nel recupero dei dettagli dell\'utente');
          fetchDocuments(null, 1);
        }
      } else {
        fetchDocuments(null, 1);
      }
    };

    initializeState();
  }, [location, fetchDocuments, userId]);

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file || !selectedUser || isUploading) return;

    setIsUploading(true);
    try {
      await uploadDocument(selectedUser._id, file);
      await fetchDocuments(selectedUser._id, currentPage);
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      toast.success('Documento caricato con successo');
    } catch (error) {
      toast.error('Errore nel caricamento del documento');
      console.error('Errore nel caricamento del documento:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDelete = async (userId, documentId) => {
    if (window.confirm('Sei sicuro di voler eliminare questo documento?')) {
      try {
        await deleteDocument(userId, documentId);
        await fetchDocuments(selectedUser?._id, currentPage);
        toast.success('Documento eliminato con successo');
      } catch (error) {
        toast.error('Errore nell\'eliminazione del documento');
        console.error('Errore nell\'eliminazione del documento:', error);
      }
    }
  };  

  const handleAnalyze = async (userId, documentId) => {
    try {
      await analyzeDocument(userId, documentId);
      await fetchDocuments(selectedUser?._id, currentPage);
      toast.success('Documento analizzato con successo');
    } catch (error) {
      toast.error('Errore nell\'analisi del documento');
      console.error('Errore nell\'analisi del documento:', error);
    }
  };

  const handleSummarize = async (userId, documentId) => {
    try {
      await summarizeDocument(userId, documentId);
      await fetchDocuments(selectedUser?._id, currentPage);
      toast.success('Documento riassunto con successo');
    } catch (error) {
      toast.error('Errore nel riassunto del documento');
      console.error('Errore nel riassunto del documento:', error);
    }
  };

  const handleUserSearch = async () => {
    if (searchTerm.length > 2) {
      try {
        const results = await searchUsers({ query: searchTerm });
        setUsers(results);
      } catch (error) {
        console.error('Errore nella ricerca degli utenti:', error);
        toast.error('Errore nella ricerca degli utenti');
      }
    }
  };

  const handleUserSelect = async (user) => {
    setSelectedUser(user);
    setSearchTerm('');
    setUsers([]);
    setCurrentPage(1);
    setShowUserSearch(false);
    await fetchDocuments(user._id, 1);
  };

  const handlePageChange = (newPage) => {
    fetchDocuments(selectedUser?._id, newPage);
  };

  const handleRowClick = (document) => {
    navigate(`${document.userId}/${document.id}`, {
      state: { fromSearch: !!selectedUser }
    });
  };

  const handleBack = () => {
    navigate('/admin/users');
  };

  return (
    <div className="document-manager">
      <h2 className="content-title">
        {selectedUser ? `Documenti di ${selectedUser.nome} ${selectedUser.cognome}` : 'Gestione Documenti'}
      </h2>
      {selectedUser && (
        <button onClick={handleBack} className="back-button" title="Torna alla lista utenti">
          <FaArrowLeft />
          <span className="button-text">Torna alla lista utenti</span>
        </button>
      )}
      <Routes>
        <Route path="/" element={
          <>
            <div className="user-actions">
              <div className="user-select-container">
                {!selectedUser ? (
                  <div className="user-search-wrapper">
                    <input 
                      type="text" 
                      value={searchTerm} 
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        if (e.target.value.length > 2) {
                          handleUserSearch();
                        }
                      }}
                      onFocus={() => setShowUserSearch(true)}
                      placeholder="Cerca utente"
                      className="user-search-input"
                    />
                    <button 
                      onClick={handleUserSearch} 
                      className="action-button search" 
                      title="Cerca Utente"
                    >
                      <FaSearch />
                    </button>
                  </div>
                ) : (
                  <div className="selected-user">
                    <span>Utente: {selectedUser.nome} {selectedUser.cognome}</span>
                    <button 
                      onClick={() => {
                        setSelectedUser(null);
                        setCurrentPage(1);
                        fetchDocuments(null, 1);
                      }} 
                      className="action-button clear"
                      title="Mostra tutti"
                    >
                      <FaUser />
                    </button>
                  </div>
                )}
                {showUserSearch && users.length > 0 && (
                  <ul className="user-list">
                    {users.map(user => (
                      <li 
                        key={user._id}
                        onClick={() => handleUserSelect(user)}
                      >
                        {user.nome} {user.cognome} ({user.email})
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {selectedUser && (
                <div className="file-upload">
                  <label htmlFor="file-upload" className="file-label">
                    {file ? file.name : 'Scegli file'}
                  </label>
                  <input 
                    id="file-upload"
                    type="file" 
                    onChange={(e) => setFile(e.target.files[0])} 
                    className="file-input"
                    ref={fileInputRef}
                  />
                  <button 
                    onClick={handleUpload} 
                    className="action-button upload" 
                    disabled={!file || isUploading}
                    title="Carica Documento"
                  >
                    <FaUpload />
                    <span className="button-text">
                      {isUploading ? 'Caricamento...' : 'Carica'}
                    </span>
                  </button>
                </div>
              )}
            </div>
            <DocumentTable 
              documents={documents}
              onAnalyze={handleAnalyze}
              onSummarize={handleSummarize}
              onDelete={handleDelete}
              onRowClick={handleRowClick}
            />
            {documents.length > 0 && (
              <div className="pagination">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="page-button"
                >
                  <FaChevronLeft />
                </button>
                <span>{`Pagina ${currentPage} di ${totalPages}`}</span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="page-button"
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
          </>
        } />
        <Route path=":userId/:documentId" element={<DocumentDetailView />} />
      </Routes>
    </div>
  );
};

export default DocumentManager;