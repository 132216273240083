const API_URL = process.env.REACT_APP_API_URL;

const handleTokenExpiration = () => {  
  localStorage.removeItem('user');  
  window.location.href = '/auth';
};

const getToken = () => {
  const userString = localStorage.getItem('user');
  if (!userString) {
    handleTokenExpiration();
    throw new Error('Utente non trovato. Effettua il login.');
  }
  const user = JSON.parse(userString);
  const token = user.token;
  if (!token) {
    handleTokenExpiration();
    throw new Error('Token non trovato. Effettua il login.');
  }

  const expiresAt = new Date(user.expiresAt);  
  if (expiresAt < new Date()) {
    handleTokenExpiration();
    throw new Error('Token scaduto. Effettua il login.');
  }
  return token;
};

export const startConversation = async () => {
  try {
    const token = getToken();
    //console.log('Iniziando una nuova conversazione...');
    const response = await fetch(`${API_URL}/api/startConversation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    //console.log('Risposta ricevuta:', response.status, response.statusText);

    const data = await response.json();

    if (response.status === 201) {
      //console.log('Nuova conversazione creata:', data);
      return { conversationId: data.conversationId };
    } else if (response.status === 200) {
      //console.log('Conversazione attiva esistente:', data);
      return { activeConversationId: data.activeConversationId };
    } else {
      throw new Error(data.message || `Errore durante l'avvio della conversazione: ${response.status}`);
    }
  } catch (error) {
    console.error('Errore dettagliato in startConversation:', error);
    throw error;
  }
};

export const closeConversation = async (conversationId) => {
  try {
    const token = getToken();
    //console.log('ConversationId:', conversationId);
    const response = await fetch(`${API_URL}/api/closeConversation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ conversationId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `Errore durante la chiusura della conversazione: ${response.status}`);
    }

    const data = await response.json();
    //console.log('Conversazione chiusa con successo:', data);
    return data;
  } catch (error) {
    console.error('Errore durante la chiusura della conversazione:', error);
    throw error;
  }
};

export const getDocuments = async (userId = null, page = 1, limit = 10) => {
  const token = getToken();
  let url = `${API_URL}/api/documents`;
  
  if (userId) {
    url += `/${userId}`;
  }
  
  url += `?page=${page}&limit=${limit}`;
  
  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero dei documenti');
  }
  
  return await response.json();
};

export const getDocument = async (userId, documentId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/documents/${userId}/${documentId}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero del documento');
  }
  return await response.json();
};

export const getDocumentContent = async (userId, documentId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/documents/${userId}/${documentId}/content`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero del contenuto del documento');
  }
  return response.blob();
};

export const updateDocument = async (userId, documentId, updateData) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/documents/${userId}/${documentId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(updateData),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'aggiornamento del documento');
  }
  return await response.json();
};

export const uploadDocument = async (userId, file) => {
  const token = getToken();
  const formData = new FormData();
  formData.append('document', file);
  formData.append('userId', userId);

  const response = await fetch(`${API_URL}/api/documents/upload`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    body: formData,
  });
  if (!response.ok) throw new Error('Errore nel caricamento del documento');
  return await response.json();
};

export const deleteDocument = async (userId, documentId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/documents`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ 
      userId: userId,
      documentIds: [documentId] 
    }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'eliminazione del documento');
  }
  return await response.json();
};

export const analyzeDocument = async (userId, documentId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/documents/analyzeByService`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ 
      userId: userId,
      documentId: documentId 
    }),
  });
  if (!response.ok) throw new Error('Errore nell\'analisi del documento');
  return await response.json();
};

export const summarizeDocument = async (userId, documentId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/documents/analyze`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ 
      userId: userId,
      documentId: documentId 
    }),
  });
  if (!response.ok) throw new Error('Errore nell\'analisi del documento');
  return await response.json();
};

export const getUsers = async () => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/users`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero degli utenti');
  }
  return await response.json();
};

export const getUserById = async (userId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/users/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero dell\'utente');
  }
  return await response.json();
};

export const updateUser = async (userId, userData) => {
  const token = getToken();
  //console.log('Updating user with ID:', userId);    
  
  const response = await fetch(`${API_URL}/api/users/${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  });
  //console.log('Response status:', response.status);
  if (!response.ok) {
    const errorData = await response.json();
    console.error('Error data:', errorData);
    throw new Error(errorData.message || 'Errore nell\'aggiornamento dell\'utente');
  }
  const result = await response.json();
  //console.log('Update result:', result);
  return result;
};

export const deleteUser = async (userId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/users/${userId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'eliminazione dell\'utente');
  }
  return await response.json();
};

export const searchUsers = async (searchParams) => {
  const token = getToken();
  const queryString = new URLSearchParams(searchParams).toString();
  const response = await fetch(`${API_URL}/api/users/search?${queryString}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nella ricerca degli utenti');
  }
  return await response.json();
};

export const createUser = async (userData) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/users/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(userData),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore durante la creazione dell\'utente');
  }
  return await response.json();
};

export const createPrompt = async (content, type) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/prompts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ content, type }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nella creazione del prompt');
  }
  return await response.json();
};

export const getPrompts = async () => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/prompts`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero dei prompt');
  }
  const data = await response.json();
  //console.log('API response data:', data); // Per debugging
  return data;
};

export const updatePrompt = async (id, content, type) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/prompts/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ content, type }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'aggiornamento del prompt');
  }
  return await response.json();
};

export const deletePrompt = async (id) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/prompts/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'eliminazione del prompt');
  }
  return await response.json();
};

export const getCompositions = async (userId = null, page = 1, limit = 10) => {
  const token = getToken();
  let url = `${API_URL}/api/compositions`;
  
  if (userId) {
    url += `/${userId}`;
  }
  
  url += `?page=${page}&limit=${limit}`;
  
  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero delle composizioni');
  }
  
  return await response.json();
};

export const getComposition = async (userId, compositionId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/compositions/${userId}/${compositionId}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero della composizione');
  }
  return await response.json();
};

export const getCompositionContent = async (userId, compositionId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/compositions/${userId}/${compositionId}/content`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero del contenuto della composizione');
  }
  const blob = await response.blob();
  return await blob.text();
};

export const generateComposition = async (userId, compositionType) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/compositions/${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ compositionType }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nella generazione della composizione');
  }
  return await response.json();
};

export const updateComposition = async (userId, compositionId, xmlContent) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/compositions/${userId}/${compositionId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/xml',
      'Authorization': `Bearer ${token}`,
    },
    body: xmlContent, // Invia direttamente il contenuto XML
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'aggiornamento della composizione');
  }
  return await response.json();
};

export const deleteComposition = async (userId, compositionId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/compositions`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ 
      userId: userId,
      compositionIds: [compositionId] 
    }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'eliminazione della composizione');
  }
  return await response.json();
};

export const retrieveRelevantChunks = async (query, limit = 5) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/chunks/retrieve?query=${encodeURIComponent(query)}&limit=${limit}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero dei chunks rilevanti');
  }
  return await response.json();
};

export const addChunk = async (text, documentId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/chunks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ text, documentId }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'aggiunta del chunk');
  }
  return await response.json();
};

export const deleteChunk = async (chunkId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/chunks/${chunkId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'eliminazione del chunk');
  }
  return await response.json();
};

export const updateChunk = async (chunkId, content) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/chunks/${chunkId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ content }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'aggiornamento del chunk');
  }
  return await response.json();
};

export const getChunksByDocumentId = async (documentId = null, page = 1, limit = 10) => {
  const token = getToken();
  let url = `${API_URL}/api/chunks/knowledge-base`;  
  if (documentId) {
    url += `/${documentId}`;
  }  
  url += `?page=${page}&limit=${limit}`;  
  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });  
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero dei chunks');
  }  
  return await response.json();
};

export const getChunkById = async (chunkId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/chunks/${chunkId}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero del chunk');
  }
  return await response.json();
};

export const uploadKnowledgeBaseDocument = async (file) => {
  const token = getToken();
  const formData = new FormData();
  formData.append('document', file);

  const response = await fetch(`${API_URL}/api/knowledge-base/upload`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    body: formData,
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel caricamento del documento nella knowledge base');
  }
  return await response.json();
};

export const getKnowledgeBaseDocument = async (documentId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/knowledge-base/${documentId}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero del documento dalla knowledge base');
  }
  return await response.json();
};

export const listKnowledgeBaseDocuments = async () => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/knowledge-base`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nel recupero della lista dei documenti dalla knowledge base');
  }
  return await response.json();
};

export const deleteKnowledgeBaseDocument = async (documentId) => {
  const token = getToken();
  const response = await fetch(`${API_URL}/api/knowledge-base/${documentId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Errore nell\'eliminazione del documento dalla knowledge base');
  }
  return await response.json();
};
