import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import DocumentManager from '../components/user/DocumentManager';
import '../styles/UserPage.css';

const UserPage = () => {
  const { user } = useAuth();

  if (!user || user.role !== 'user') {
    return <Navigate to="/" />;
  }

  return (
    <div className="page-container user-page">
      <h1 className="page-title">Area Utente</h1>
      <Routes>
        <Route path="documents" element={<DocumentManager />} />
        <Route path="*" element={<Navigate to="documents" replace />} />
      </Routes>
    </div>
  );
};

export default UserPage;