import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { getCompositions, generateComposition, deleteComposition, getCompositionContent, searchUsers, getUserById } from '../../services/api';
import CompositionTable from './CompositionTable';
import CompositionDetailView from './CompositionDetailView';
import { FaPlus, FaUser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import '../../styles/AdminDashboard.css';
import '../../styles/CompositionManagement.css';

const CompositionManager = () => {
  const [compositions, setCompositions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchCompositions = useCallback(async (userId = null, page = 1) => {
    try {
      const response = await getCompositions(userId, page);
      setCompositions(response.compositions);
      setTotalPages(response.totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error('Errore nel recupero delle composizioni');
      console.error('Errore nel recupero delle composizioni:', error);
    }
  }, []);
  
  useEffect(() => {
    const initializeState = async () => {
      if (location.state && location.state.selectedUserId) {
        try {
          const user = await getUserById(location.state.selectedUserId);
          setSelectedUser(user);
          fetchCompositions(user._id, 1);
        } catch (error) {
          console.error('Errore nel recupero dei dettagli dell\'utente:', error);
          toast.error('Errore nel recupero dei dettagli dell\'utente');
          fetchCompositions(null, 1);
        }
      } else {
        fetchCompositions(null, 1);
      }
    };

    initializeState();
  }, [location, fetchCompositions]);

  const handleGenerate = async () => {
    if (!selectedUser || isGenerating) return;

    setIsGenerating(true);
    try {
      await generateComposition(selectedUser._id, '730');
      await fetchCompositions(selectedUser._id, currentPage);
      toast.success('Composizione generata con successo');
    } catch (error) {
      toast.error('Errore nella generazione della composizione');
      console.error('Errore nella generazione della composizione:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDownload = async (composition) => {
    try {
      const content = await getCompositionContent(composition.userId, composition._id);
      const blob = new Blob([content], { type: 'application/xml' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `composizione_${composition._id}.xml`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Errore nel download della composizione:', error);
      toast.error('Errore nel download della composizione');
    }
  };

  const handleEdit = (composition) => {
    navigate(`${composition.userId}/${composition._id}`, {
      state: { fromSearch: !!selectedUser }
    });
  };  

  const handleDelete = async (userId, compositionId) => {
    if (window.confirm('Sei sicuro di voler eliminare questa composizione?')) {
      try {
        await deleteComposition(userId, compositionId);
        await fetchCompositions(selectedUser?._id, currentPage);
        toast.success('Composizione eliminata con successo');
      } catch (error) {
        toast.error('Errore nell\'eliminazione della composizione');
        console.error('Errore nell\'eliminazione della composizione:', error);
      }
    }
  };

  const handleUserSearch = useCallback(async () => {
    if (searchTerm.length > 2) {
      try {
        const results = await searchUsers({ query: searchTerm });
        setUsers(results);
      } catch (error) {
        console.error('Errore nella ricerca degli utenti:', error);
        toast.error('Errore nella ricerca degli utenti');
      }
    } else {
      setUsers([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleUserSearch();
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm, handleUserSearch]);

  const handleUserSelect = async (user) => {
    setSelectedUser(user);
    setSearchTerm('');
    setUsers([]);
    setCurrentPage(1);
    await fetchCompositions(user._id, 1);
  };

  const handlePageChange = (newPage) => {
    fetchCompositions(selectedUser?._id, newPage);
  };

  return (
    <div className="composition-manager">
      <h2 className="content-title">Gestione Composizioni</h2>
      <Routes>
        <Route path="/" element={
          <>
            <div className="user-actions">
              {!selectedUser ? (
                <div className="user-select-container">
                  <div className="user-search-wrapper">
                    <FaUser className="user-icon" />
                    <input 
                      type="text" 
                      value={searchTerm} 
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Cerca utente"
                      className="user-search-input"
                    />
                  </div>
                  {users.length > 0 && (
                    <ul className="user-list">
                      {users.map(user => (
                        <li 
                          key={user._id}
                          onClick={() => handleUserSelect(user)}
                        >
                          {user.nome} {user.cognome} ({user.email})
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                <div className="selected-user">
                  <span>Utente selezionato: {selectedUser.nome} {selectedUser.cognome}</span>
                  <button onClick={() => {
                    setSelectedUser(null);
                    setCurrentPage(1);
                    fetchCompositions(null, 1);
                  }} className="action-button">
                    Mostra tutti
                  </button>
                </div>
              )}
              {selectedUser && (
                <div className="generate-composition">
                  <button 
                    onClick={handleGenerate} 
                    className="action-button generate" 
                    disabled={isGenerating}
                    title="Genera Composizione"
                  >
                    <FaPlus />
                    <span className="action-text">
                      {isGenerating ? 'Generazione...' : 'Genera Composizione'}
                    </span>
                  </button>
                </div>
              )}
            </div>
            <CompositionTable 
              compositions={compositions}
              onDelete={handleDelete}
              onDownload={handleDownload}
              onEdit={handleEdit}
            />
            {compositions.length > 0 && (
              <div className="pagination">
                <button 
                  onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} 
                  disabled={currentPage === 1}
                >
                  Precedente
                </button>
                <span>Pagina {currentPage} di {totalPages}</span>
                <button 
                  onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} 
                  disabled={currentPage === totalPages}
                >
                  Successiva
                </button>
              </div>
            )}
          </>
        } />
        <Route path=":userId/:compositionId" element={<CompositionDetailView />} />
      </Routes>
    </div>
  );
};

export default CompositionManager;