import React, { useState, useEffect, useCallback } from 'react';
import { getDocuments, uploadDocument, deleteDocument } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';

const DocumentManager = () => {
  const [documents, setDocuments] = useState([]);
  const [file, setFile] = useState(null);
  const { user } = useAuth();

  const fetchDocuments = useCallback(async () => {
    try {
      const docs = await getDocuments(user.id);
      setDocuments(docs);
    } catch (error) {
      console.error('Errore nel recupero dei documenti:', error);
    }
  }, [user.id]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) return;

    try {
      await uploadDocument(user.id, file);
      fetchDocuments();
      setFile(null);
    } catch (error) {
      console.error('Errore nel caricamento del documento:', error);
    }
  };

  const handleDelete = async (documentId) => {
    try {
      await deleteDocument(user.id, documentId);
      fetchDocuments();
    } catch (error) {
      console.error('Errore nell\'eliminazione del documento:', error);
    }
  };

  return (
    <div className="document-manager">
      <h2>I Miei Documenti</h2>
      <form onSubmit={handleUpload}>
        <input 
          type="file" 
          onChange={(e) => setFile(e.target.files[0])} 
          accept=".pdf,.doc,.docx,.txt"
        />
        <button type="submit" disabled={!file}>Carica Documento</button>
      </form>
      {documents.length === 0 ? (
        <p>Non hai ancora caricato documenti.</p>
      ) : (
        <ul>
          {documents.map((doc) => (
            <li key={doc.id}>
              {doc.name}
              <button onClick={() => handleDelete(doc.id)}>Elimina</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DocumentManager;