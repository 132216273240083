import React, { useState } from 'react';
import { retrieveRelevantChunks } from '../../services/api';
import '../../styles/AdminDashboard.css';
import '../../styles/Retrieval.css';

const Retrieval = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(5);
  const [hasSearched, setHasSearched] = useState(false);

  const handleRetrieval = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setHasSearched(true);
    try {
      const response = await retrieveRelevantChunks(query, limit);
      console.log('API response:', response);
      setResults(response.relevantChunks || []);
    } catch (error) {
      console.error('Error during retrieval:', error);
      setError('Si è verificato un errore durante la ricerca. Riprova più tardi.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLimitChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setLimit(value);
    } else if (e.target.value === '') {
      setLimit('');
    }
  };

  return (
    <div className="retrieval-container">
      <h2 className="content-title">Ricerca nel Knowledge Base</h2>
      <form onSubmit={handleRetrieval} className="retrieval-form">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Inserisci la tua query"
          className="retrieval-input"
        />
        <div className="limit-input-container">
          <input
            type="number"
            value={limit}
            onChange={handleLimitChange}
            placeholder="Num. risultati"
            className="limit-input"
            min="1"
          />
          <span className="limit-tooltip">Numero di risultati da visualizzare</span>
        </div>
        <button type="submit" className="action-button" disabled={isLoading || limit === '' || limit < 1}>
          {isLoading ? 'Ricerca in corso...' : 'Cerca'}
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
      {results.length > 0 && (
        <div className="results-container">
          <h3>Risultati:</h3>
          <ul className="results-list">
            {results.map((result, index) => (
              <li key={index} className="result-item">
                <p className="result-text">{result.content || 'Contenuto non disponibile'}</p>
                <p className="result-weight">Peso: {result.weight}</p>
                <p className="result-document">Documento: {result.documentName || 'Nome documento non disponibile'}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
      {hasSearched && results.length === 0 && !isLoading && <p>Nessun risultato trovato.</p>}
    </div>
  );
};

export default Retrieval;