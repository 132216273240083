import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { useAuth } from './hooks/useAuth';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Sidebar from './components/common/Sidebar';
import HomePage from './pages/HomePage';
import ChatPage from './pages/ChatPage';
import AdminPage from './pages/AdminPage';
import UserPage from './pages/UserPage';
import AuthPage from './pages/AuthPage';
import { ToastContainer } from 'react-toastify';
import './styles/App.css';
import './styles/Common.css';
import 'react-toastify/dist/ReactToastify.css';

function AppContent() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [shouldOpenSidebar, setShouldOpenSidebar] = useState(false);
  const { user } = useAuth();
  const location = useLocation();

  const isAdminOrUserRoute = useCallback(() => {
    return location.pathname.startsWith('/admin') || location.pathname.startsWith('/user');
  }, [location]);

  useEffect(() => {
    if (user && isAdminOrUserRoute()) {
      setShouldOpenSidebar(true);
    } else {
      setShouldOpenSidebar(false);
    }
  }, [user, isAdminOrUserRoute]);

  useEffect(() => {
    if (shouldOpenSidebar) {
      setSidebarOpen(true);
    }
  }, [shouldOpenSidebar]);

  useEffect(() => {
    if (!user) {
      setSidebarOpen(false);
    }
  }, [user]);

  const toggleSidebar = () => setSidebarOpen(prev => !prev);

  return (
    <div className="App">
      <Header />
      <div className={`main-content ${sidebarOpen ? 'sidebar-open' : ''}`}>
        {user && <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />}
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/chat" element={<ChatPage />} />
            <Route 
              path="/admin/*" 
              element={
                user && user.role === 'admin' 
                  ? <AdminPage /> 
                  : <Navigate to="/" replace />
              } 
            />
            <Route 
              path="/user/*" 
              element={
                user && user.role === 'user' 
                  ? <UserPage /> 
                  : <Navigate to="/" replace />
              } 
            />
            <Route path="/auth" element={<AuthPage />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <ToastContainer 
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;