import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { TbAnalyze } from "react-icons/tb";
import { MdSummarize } from "react-icons/md";
import '../../styles/AdminDashboard.css';
import '../../styles/DocumentManagement.css';

const DocumentTable = ({ documents, onAnalyze, onSummarize, onDelete, onRowClick }) => {
  return (
    <table className="admin-table">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Tipo Documento</th>
          <th>Stato Documento</th>
          <th>Utente</th>
          <th>Data Creazione</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        {documents.map((doc) => (
          <tr key={doc.id} onClick={() => onRowClick(doc)} style={{ cursor: 'pointer' }}>
            <td>{doc.nome}</td>
            <td>{doc.tipoDocumento}</td>
            <td>{doc.statoDocumento}</td>
            <td>{doc.userName}</td>
            <td>{new Date(doc.dataCreazione).toLocaleDateString()}</td>
            <td onClick={(e) => e.stopPropagation()}>
              <button onClick={() => onAnalyze(doc.userId, doc.id)} className="action-button analyze">
                <TbAnalyze />
                <span className="action-text">Analizza</span>
              </button>
              <button onClick={() => onSummarize(doc.userId, doc.id)} className="action-button summarize">
                <MdSummarize />
                <span className="action-text">Riassumi</span>
              </button>
              <button 
                onClick={() => onDelete(doc.userId, doc.id)} 
                className="action-button delete"
              >
                <FaTrash />
                <span className="action-text">Elimina</span>
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DocumentTable;