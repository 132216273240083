import React, { useState, useEffect } from 'react';

const PromptForm = ({ prompt, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    content: '',
    type: ''
  });

  useEffect(() => {
    if (prompt) {
      setFormData({
        content: prompt.content || '',
        type: prompt.type || ''
      });
    } else {
      setFormData({
        content: '',
        type: ''
      });
    }
  }, [prompt]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="admin-form">
      <div className="form-group">
        <label htmlFor="content">Contenuto:</label>
        <textarea
          id="content"
          name="content"
          value={formData.content}
          onChange={handleChange}
          required
          rows={10}
          style={{ width: '100%', minHeight: '200px' }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="type">Tipo:</label>
        <input
          type="text"
          id="type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-actions">
        <button type="submit" className="submit-button">
          {prompt ? 'Aggiorna Prompt' : 'Crea Prompt'}
        </button>
        <button type="button" onClick={onCancel} className="cancel-button">
          Annulla
        </button>
      </div>
    </form>
  );
};

export default PromptForm;