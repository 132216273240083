import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getDocument, analyzeDocument, summarizeDocument, deleteDocument, updateDocument, getDocumentContent } from '../../services/api';
import { toast } from 'react-toastify';
import StructuredDataEditor from './StructuredDataEditor';
import ImageViewer from './ImageViewer';

const DocumentDetailView = () => {
    const { userId, documentId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [documentData, setDocumentData] = useState(null);
    const [localDocumentData, setLocalDocumentData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [documentBlob, setDocumentBlob] = useState(null);
    const structuredDataEditorRef = React.useRef(null);
    const localDocumentTypeRef = React.useRef('');
    const localSummaryRef = React.useRef('');

    const fetchDocument = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await getDocument(userId, documentId);      
            setDocumentData(data);
            setLocalDocumentData(data);
            localDocumentTypeRef.current = data.documentType || '';
            localSummaryRef.current = data.summary || '';
            const blob = await getDocumentContent(userId, documentId);
            setDocumentBlob(blob);
        } catch (error) {
            console.error('Errore nel recupero del documento:', error);
            toast.error('Errore nel recupero del documento');
        } finally {
            setIsLoading(false);
        }
    }, [userId, documentId]);

    useEffect(() => {
        fetchDocument();
    }, [fetchDocument]);

    const handleStructuredDataChange = (newStructuredData) => {
        setLocalDocumentData(prevData => ({
            ...prevData,
            structuredData: newStructuredData
        }));
    };

    const handleInputChange = (field, value) => {
        if (field === 'documentType') {
            localDocumentTypeRef.current = value;
        } else if (field === 'summary') {
            localSummaryRef.current = value;
        }
    };

    const handleAnalyze = async () => {
        try {
            await analyzeDocument(userId, documentId);
            toast.success('Documento analizzato con successo');
            fetchDocument();
        } catch (error) {
            console.error('Errore nell\'analisi del documento:', error);
            toast.error('Errore nell\'analisi del documento');
        }
    };

    const handleSummarize = async () => {
        try {
            await summarizeDocument(userId, documentId);
            toast.success('Documento riassunto con successo');
            fetchDocument();
        } catch (error) {
            console.error('Errore nel riassunto del documento:', error);
            toast.error('Errore nel riassunto del documento');
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Sei sicuro di voler eliminare questo documento?')) {
            try {
                await deleteDocument(userId, documentId);
                toast.success('Documento eliminato con successo');
                handleGoBack();
            } catch (error) {
                console.error('Errore nell\'eliminazione del documento:', error);
                toast.error('Errore nell\'eliminazione del documento');
            }
        }
    };

    const handleUpdate = async () => {
        setIsUpdating(true);
        try {
            const localWarnings = structuredDataEditorRef.current.getLocalWarnings();
            const updateData = {
                documentType: localDocumentTypeRef.current,
                summary: localSummaryRef.current,
                structuredData: {
                    ...localDocumentData.structuredData,
                    warnings: localWarnings
                }
            };
            await updateDocument(userId, documentId, updateData);
            toast.success('Documento aggiornato con successo');
            await fetchDocument();
        } catch (error) {
            console.error('Errore nell\'aggiornamento del documento:', error);
            toast.error('Errore nell\'aggiornamento del documento');
        } finally {
            setIsUpdating(false);
        }
    };

    const handleGoBack = () => {
        if (location.state && location.state.fromSearch) {
            navigate('/admin/documents', { 
                state: { selectedUserId: userId }
            });
        } else {
            navigate('/admin/documents');
        }
    };

    const renderDocumentPreview = () => {
        if (!documentData || !documentBlob) {
            return <div>Nessuna anteprima disponibile</div>;
        }

        return (
            <div className="document-preview-container">
                {renderPreviewContent()}
            </div>
        );
    };

    const renderPreviewContent = () => {
        if (!documentData || !documentBlob) {
          return <div>Caricamento anteprima...</div>;
        }
    
        const objectUrl = URL.createObjectURL(documentBlob);
    
        if (documentData.mimeType.startsWith('image/')) {
          return (
            <ImageViewer
              src={objectUrl}
              fileName={documentData.originalName}
            />
          );
        } else {
          return (
            <iframe
              src={objectUrl}
              title="Document Viewer"
              className="document-viewer"
            />
          );
        }
      };

    if (isLoading) return <div>Caricamento...</div>;
    if (!localDocumentData) return <div>Documento non trovato</div>;  

    return (
        <div className="document-detail-view">
            <h2>Dettagli Documento</h2>
            <div className="document-content">
                <div className="document-preview">
                    {renderDocumentPreview()}
                </div>
                <div className="document-info">
                    <div className="field">
                        <label>Tipo Documento:</label>
                        <input
                            type="text"
                            defaultValue={localDocumentData.documentType || ''}
                            onChange={(e) => handleInputChange('documentType', e.target.value)}
                        />
                    </div>
                    <div className="field">
                        <label>Riepilogo:</label>
                        <textarea
                            defaultValue={localDocumentData.summary || ''}
                            onChange={(e) => handleInputChange('summary', e.target.value)}
                        />
                    </div>
                    <StructuredDataEditor
                        ref={structuredDataEditorRef}
                        structuredData={localDocumentData.structuredData || { values: {}, warnings: [] }}
                        onStructuredDataChange={handleStructuredDataChange}
                    />
                </div>
            </div>
            <div className="document-actions">
                <button onClick={handleGoBack} className="action-button back">Indietro</button>
                <button onClick={handleAnalyze} className="action-button analyze">Analizza</button>
                <button onClick={handleSummarize} className="action-button summarize">Riassumi</button>
                <button onClick={handleUpdate} className="action-button" disabled={isUpdating}>
                    {isUpdating ? 'Aggiornamento...' : 'Aggiorna'}
                </button>
                <button onClick={handleDelete} className="action-button delete">Elimina</button>
            </div>
        </div>
    );
};

export default DocumentDetailView;