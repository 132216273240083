import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import '../../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>FISCALia</h4>
          <p>Il tuo assistente fiscale intelligente</p>
        </div>
        <div className="footer-section">
          <h4>Link utili</h4>
          <ul>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/termini">Termini di Servizio</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Contattaci</h4>
          <p>Email: info@fiscalia.it</p>
          <p>Tel: +39 02 1234567</p>
        </div>
        <div className="footer-section">
          <h4>Seguici</h4>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 FISCALia. Tutti i diritti riservati.</p>
      </div>
    </footer>
  );
};

export default Footer;