import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getComposition, getCompositionContent, updateComposition, deleteComposition } from '../../services/api';
import { FaDownload, FaSave, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import '../../styles/CompositionManagement.css';

const CompositionDetailView = () => {
    const { userId, compositionId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [compositionData, setCompositionData] = useState(null);
    const [compositionContent, setCompositionContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);

    const fetchComposition = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await getComposition(userId, compositionId);
            setCompositionData(data);
            const content = await getCompositionContent(userId, compositionId);
            setCompositionContent(content);
        } catch (error) {
            console.error('Errore nel recupero della composizione:', error);
            toast.error('Errore nel recupero della composizione');
        } finally {
            setIsLoading(false);
        }
    }, [userId, compositionId]);

    useEffect(() => {
        fetchComposition();
    }, [fetchComposition]);

    const handleUpdate = async () => {
        setIsUpdating(true);
        try {
            await updateComposition(userId, compositionId, compositionContent);
            toast.success('Composizione aggiornata con successo');
            await fetchComposition();
        } catch (error) {
            console.error('Errore nell\'aggiornamento della composizione:', error);
            toast.error('Errore nell\'aggiornamento della composizione');
        } finally {
            setIsUpdating(false);
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Sei sicuro di voler eliminare questa composizione?')) {
            try {
                await deleteComposition(userId, compositionId);
                toast.success('Composizione eliminata con successo');
                handleGoBack();
            } catch (error) {
                console.error('Errore nell\'eliminazione della composizione:', error);
                toast.error('Errore nell\'eliminazione della composizione');
            }
        }
    };

    const handleDownload = useCallback(() => {
        if (compositionContent) {
            const blob = new Blob([compositionContent], { type: 'application/xml' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `composizione_${compositionId}.xml`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }
    }, [compositionContent, compositionId]);

    const handleGoBack = () => {
        if (location.state && location.state.fromSearch) {
            navigate('/admin/compositions', { 
                state: { selectedUserId: userId }
            });
        } else {
            navigate('/admin/compositions');
        }
    };

    if (isLoading) return <div>Caricamento...</div>;
    if (!compositionData) return <div>Composizione non trovata</div>;

    return (
        <div className="composition-detail-view">
            <h2>Dettagli Composizione</h2>
            <div className="composition-info">
                <p><strong>ID:</strong> {compositionData.id}</p>
                <p><strong>Tipo:</strong> {compositionData.compositionType}</p>
                <p><strong>Utente:</strong> {compositionData.userName}</p>
                <p><strong>Data Creazione:</strong> {new Date(compositionData.createdAt).toLocaleString()}</p>
            </div>
            <div className="composition-content">
                <h3>Contenuto XML</h3>
                <textarea
                    value={compositionContent}
                    onChange={(e) => setCompositionContent(e.target.value)}
                    rows={20}
                    className="xml-editor"
                />
            </div>
            <div className="composition-actions">
                <button onClick={handleGoBack} className="action-button back">Indietro</button>
                <button onClick={handleUpdate} className="action-button" disabled={isUpdating}>
                    <FaSave />
                    <span className="action-text">{isUpdating ? 'Aggiornamento...' : 'Aggiorna'}</span>
                </button>
                <button onClick={handleDownload} className="action-button download">
                    <FaDownload />
                    <span className="action-text">Scarica XML</span>
                </button>
                <button onClick={handleDelete} className="action-button delete">
                    <FaTrash />
                    <span className="action-text">Elimina</span>
                </button>
            </div>
        </div>
    );
};

export default CompositionDetailView;