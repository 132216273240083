import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FaUser, FaFileAlt, FaSearch, FaChevronLeft, FaChevronRight, FaThLarge, FaBook, FaPaste, FaTerminal } from 'react-icons/fa';
import { useAuth } from '../../hooks/useAuth';
import '../../styles/Sidebar.css';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  if (!user) return null;

  const sidebarClass = isOpen ? 'sidebar open' : 'sidebar compact';
  const isDashboardPage = location.pathname.startsWith('/admin') || location.pathname.startsWith('/user');

  const NavItem = ({ to, icon, text }) => {
    const isActive = location.pathname.startsWith(to);
    
    const handleClick = (e) => {
      if (!isOpen) {
        e.preventDefault();
        navigate(to);
      }      
    };
  
    return (
      <NavLink 
        to={to} 
        className={({ isActive }) => `nav-item ${isActive && isDashboardPage ? 'active' : ''}`}
        onClick={handleClick}
      >
        {icon}
        <span className={`nav-text ${!isOpen ? 'hidden' : ''}`}>{text}</span>
        {!isOpen && isActive && isDashboardPage && <span className="active-indicator"></span>}
      </NavLink>
    );
  };

  return (
    <div className={sidebarClass}>
      <nav>
        <ul>
          {user.role === 'admin' ? (
            <>
              <li><NavItem to="/admin/users" icon={<FaUser />} text="Gestione Utenti" /></li>
              <li><NavItem to="/admin/documents" icon={<FaFileAlt />} text="Gestione Documenti" /></li>
              <li><NavItem to="/admin/compositions" icon={<FaPaste />} text="Gestione Composizioni" /></li>
              <li><NavItem to="/admin/retrieval" icon={<FaSearch />} text="Retrieval" /></li>
              <li><NavItem to="/admin/knowlegebase" icon={<FaBook />} text="Gestione Knowledge" /></li>
              <li><NavItem to="/admin/chunks" icon={<FaThLarge />} text="Gestione Chunks" /></li>
              <li><NavItem to="/admin/prompts" icon={<FaTerminal />} text="Gestione Prompt" /></li>
            </>
          ) : (
            <li><NavItem to="/user/documents" icon={<FaFileAlt />} text="I miei Documenti" /></li>
          )}
        </ul>
      </nav>
      <button className="toggle-sidebar" onClick={toggleSidebar}>
        {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
      </button>
    </div>
  );
};

export default Sidebar;