import React, { useState, useEffect, useCallback } from 'react';
import { getPrompts, createPrompt, updatePrompt, deletePrompt } from '../../services/api';
import PromptTable from './PromptTable';
import PromptForm from './PromptForm';
import { toast } from 'react-toastify';
import '../../styles/AdminDashboard.css';
import { FaPlus } from 'react-icons/fa';

const PromptManager = () => {
  const [allPrompts, setAllPrompts] = useState([]);
  const [displayedPrompts, setDisplayedPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  const fetchPrompts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getPrompts();
      console.log('API Response:', response); // Per debugging
      if (Array.isArray(response)) {
        setAllPrompts(response);
        setTotalPages(Math.ceil(response.length / itemsPerPage));
      } else {
        console.error('Unexpected API response structure:', response);
        setAllPrompts([]);
        toast.error('Errore nel formato dei dati ricevuti');
      }
    } catch (error) {
      console.error('Error fetching prompts:', error);
      setAllPrompts([]);
      toast.error('Errore nel caricamento dei prompt');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPrompts();
  }, [fetchPrompts]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDisplayedPrompts(allPrompts.slice(startIndex, endIndex));
  }, [currentPage, allPrompts]);

  const handleCreatePrompt = async (promptData) => {
    try {
      await createPrompt(promptData.content, promptData.type);
      toast.success('Prompt creato con successo');
      setIsCreating(false);
      fetchPrompts();
    } catch (error) {
      console.error('Errore nella creazione del prompt:', error);
      toast.error('Errore nella creazione del prompt');
    }
  };

  const handleUpdatePrompt = async (promptData) => {
    try {
      await updatePrompt(selectedPrompt._id, promptData.content, promptData.type);
      toast.success('Prompt aggiornato con successo');
      setSelectedPrompt(null);
      fetchPrompts();
    } catch (error) {
      console.error('Errore nell\'aggiornamento del prompt:', error);
      toast.error('Errore nell\'aggiornamento del prompt');
    }
  };

  const handleSubmitPrompt = (promptData) => {
    if (selectedPrompt) {
      handleUpdatePrompt(promptData);
    } else {
      handleCreatePrompt(promptData);
    }
  };

  const handleEditPrompt = (prompt) => {
    setSelectedPrompt(prompt);
    setIsCreating(false);
  };

  const handleDeletePrompt = async (promptId) => {
    try {
      await deletePrompt(promptId);
      toast.success('Prompt eliminato con successo');
      fetchPrompts();
    } catch (error) {
      console.error('Error deleting prompt:', error);
      toast.error('Errore nell\'eliminazione del prompt');
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (isLoading) {
    return <p>Caricamento prompt...</p>;
  }

  return (
    <div className="prompt-manager">
      <h2>Gestione Prompt</h2>
      {isCreating || selectedPrompt ? (
        <PromptForm 
          prompt={selectedPrompt} 
          onSubmit={handleSubmitPrompt} 
          onCancel={() => {
            setIsCreating(false);
            setSelectedPrompt(null);
          }} 
        />
      ) : (
        <>
          <div className="prompt-actions">
            <button onClick={() => setIsCreating(true)} className="action-button create" title="Crea Nuovo Prompt">
              <FaPlus />
              <span className="action-text">Crea Nuovo Prompt</span>
            </button>
          </div>
          {displayedPrompts.length > 0 ? (
            <>
              <PromptTable 
                prompts={displayedPrompts} 
                onEdit={handleEditPrompt} 
                onDelete={handleDeletePrompt}
              />
              <div className="pagination">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`page-button ${currentPage === page ? 'active' : ''}`}
                  >
                    {page}
                  </button>
                ))}
              </div>
            </>
          ) : (
            <p>Nessun prompt trovato.</p>
          )}
        </>
      )}
    </div>
  );
};

export default PromptManager;