import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUsers, createUser, updateUser, deleteUser, searchUsers } from '../../services/api';
import UserTable from './UserTable';
import UserForm from './UserForm';
import UserDocuments from './UserDocuments';
import { toast } from 'react-toastify';
import '../../styles/UserManagement.css';
import { FaPlus, FaSearch } from 'react-icons/fa'; 

const UserManager = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDocuments, setShowDocuments] = useState(false);
  const [selectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const fetchedUsers = await getUsers();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
      toast.error('Errore nel caricamento degli utenti');
    } finally {
      setIsLoading(false);
    }
  }; 

  const handleCreateUser = async (userData) => {
    try {
      await createUser(userData);
      await fetchUsers();
      setIsCreating(false);
      toast.success('Utente creato con successo');
    } catch (error) {
      console.error('Errore nella creazione dell\'utente:', error);
      toast.error('Errore nella creazione dell\'utente');
    }
  };

  const handleUpdateUser = async (userData) => {
    try {
      if (!userData._id) {
        throw new Error('ID utente mancante per l\'aggiornamento');
      }
      await updateUser(userData._id, userData);
      await fetchUsers();
      setSelectedUser(null);
      toast.success('Utente aggiornato con successo');
    } catch (error) {
      console.error('Errore nell\'aggiornamento dell\'utente:', error);
      toast.error('Errore nell\'aggiornamento dell\'utente');
    }
  };

  const handleSubmitUser = (userData) => {
    if (userData._id) {
      handleUpdateUser(userData);
    } else {
      handleCreateUser(userData);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId);
      await fetchUsers();
      toast.success('Utente eliminato con successo');
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Errore nell\'eliminazione dell\'utente');
    }
  };

  const handleSearch = async () => {
    try {
      const searchResults = await searchUsers({ query: searchTerm });
      setUsers(searchResults);
    } catch (error) {
      console.error('Error searching users:', error);
      toast.error('Errore nella ricerca degli utenti');
    }
  };

  const handleViewDocuments = (userId) => {
    navigate(`/admin/documents/user/${userId}`);
  };

  if (isLoading) {
    return <p>Caricamento utenti...</p>;
  }

  return (
    <div className="user-manager">
      <h2>Gestione Utenti</h2>      
      <div className="user-actions">
        <input 
          type="text" 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Cerca utenti"
        />
        <button onClick={handleSearch} className="action-button search" title="Cerca">
          <FaSearch />
          <span className="action-text">Cerca</span>
        </button>
        <button onClick={() => setIsCreating(true)} className="action-button create" title="Crea Nuovo Utente">
          <FaPlus />
          <span className="action-text">Crea Nuovo Utente</span>
        </button>
      </div>
      {isCreating || selectedUser ? (
        <UserForm 
          user={selectedUser} 
          onSubmit={handleSubmitUser} 
          onCancel={() => {
            setIsCreating(false);
            setSelectedUser(null);
          }} 
          showValidationFields={true}
        />
      ) : showDocuments ? (
        <UserDocuments 
          userId={selectedUserId} 
          onBack={() => setShowDocuments(false)} 
        />
      ) : (
        <UserTable 
          users={users} 
          onEdit={setSelectedUser} 
          onDelete={handleDeleteUser}
          onViewDocuments={handleViewDocuments}
        />
      )}
    </div>
  );
};

export default UserManager;