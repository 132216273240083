import React, { useState, useEffect } from 'react';
import { getChunkById, updateChunk, deleteChunk } from '../../services/api';
import { toast } from 'react-toastify';
import '../../styles/KnowledgeBaseManagement.css';

const ChunkDetailView = ({ chunkId, onClose }) => {
  const [chunk, setChunk] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editedContent, setEditedContent] = useState('');

  useEffect(() => {
    const fetchChunk = async () => {
      setIsLoading(true);
      try {
        const data = await getChunkById(chunkId);
        setChunk(data);
        setEditedContent(data.content);
      } catch (error) {
        console.error('Errore nel recupero del chunk:', error);
        toast.error('Errore nel recupero del chunk');
      } finally {
        setIsLoading(false);
      }
    };

    if (chunkId) {
      fetchChunk();
    }
  }, [chunkId]);

  const handleUpdate = async () => {
    setIsUpdating(true);
    try {
      const updatedChunk = await updateChunk(chunkId, editedContent);
      setChunk(updatedChunk);
      toast.success('Chunk aggiornato con successo');
    } catch (error) {
      console.error('Errore nell\'aggiornamento del chunk:', error);
      toast.error('Errore nell\'aggiornamento del chunk');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Sei sicuro di voler eliminare questo chunk?')) {
      try {
        await deleteChunk(chunkId);
        toast.success('Chunk eliminato con successo');
        onClose(); // Chiude la vista dettagliata e torna alla lista
      } catch (error) {
        console.error('Errore nell\'eliminazione del chunk:', error);
        toast.error('Errore nell\'eliminazione del chunk');
      }
    }
  };

  if (isLoading) return <div>Caricamento...</div>;
  if (!chunk) return <div>Chunk non trovato</div>;

  return (
    <div className="chunk-detail-view">
      <h2>Dettagli Chunk</h2>
      <div className="chunk-info">
        <p><strong>ID:</strong> {chunk._id}</p>
        <p><strong>Document ID:</strong> {chunk.documentId}</p>
      </div>
      <div className="chunk-content">
        <h3>Contenuto</h3>
        <textarea
          value={editedContent}
          onChange={(e) => setEditedContent(e.target.value)}
          rows={10}
          className="chunk-editor"
        />
      </div>
      <div className="chunk-actions">
        <button onClick={onClose} className="action-button">Indietro</button>
        <button 
          onClick={handleUpdate} 
          className="action-button" 
          disabled={isUpdating || editedContent === chunk.content}
        >
          {isUpdating ? 'Aggiornamento...' : 'Aggiorna'}
        </button>
        <button onClick={handleDelete} className="action-button delete">Elimina</button>
      </div>
    </div>
  );
};

export default ChunkDetailView;