import React from 'react';
import { FaEdit, FaTrash, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../../styles/AdminDashboard.css';
import '../../styles/KnowledgeBaseManagement.css';

const ChunkTable = ({ chunks, onEdit, onDelete, onPageChange, currentPage, totalPages }) => {
  return (
    <div className="chunk-table">
      <table className="admin-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Contenuto</th>
            <th>Document ID</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {chunks.map((chunk) => (
            <tr key={chunk._id}>
              <td>{chunk._id}</td>
              <td>
                {chunk.content.length > 50 ? `${chunk.content.substring(0, 50)}...` : chunk.content}
              </td>
              <td>{chunk.documentId}</td>
              <td>
                <button onClick={() => onEdit(chunk._id)} className="action-button edit" title="Visualizza/Modifica">
                  <FaEdit />
                </button>
                <button onClick={() => onDelete(chunk._id)} className="action-button delete" title="Elimina">
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {chunks.length > 0 && (
        <div className="pagination">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="page-button"
          >
            <FaChevronLeft />
          </button>
          <span>{`Pagina ${currentPage} di ${totalPages}`}</span>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="page-button"
          >
            <FaChevronRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default ChunkTable;