import React from 'react';

export const FISCALiaLogo = ({ className = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 80" className={`fiscalia-logo ${className}`}>
    <defs>
      <linearGradient id="textGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{stopColor:'#6e8efb', stopOpacity:1}} />
        <stop offset="100%" style={{stopColor:'#a777e3', stopOpacity:1}} />
      </linearGradient>
    </defs>
    <rect x="10" y="10" width="60" height="60" rx="15" fill="url(#textGradient)" />
    <path d="M25 45 Q40 20 55 45" stroke="white" strokeWidth="6" fill="none" strokeLinecap="round" />
    <circle cx="40" cy="50" r="5" fill="white" />
  </svg>
);

export const FISCALiaFullLogo = ({ className = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 80" className={`fiscalia-logo ${className}`}>
    <defs>
      <linearGradient id="textGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{stopColor:'#6e8efb', stopOpacity:1}} />
        <stop offset="100%" style={{stopColor:'#a777e3', stopOpacity:1}} />
      </linearGradient>
    </defs>
    <rect x="10" y="10" width="60" height="60" rx="15" fill="url(#textGradient)" />
    <path d="M25 45 Q40 20 55 45" stroke="white" strokeWidth="6" fill="none" strokeLinecap="round" />
    <circle cx="40" cy="50" r="5" fill="white" />
    <text x="80" y="55" fontFamily="'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" fontSize="36" fontWeight="bold" fill="white">
      FISCALia
    </text>
    <text x="82" y="70" fontFamily="'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" fontSize="16" fill="#e0e0e0">
      Il bot 730 5.0
    </text>
  </svg>
);