import React, { useState, useEffect } from 'react';
import '../../styles/UserManagement.css';

const UserForm = ({ user, onSubmit, onCancel, showValidationFields = false }) => {
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    email: '',
    password: '',
    role: 'user',
    isValidated: false,
    isActive: false
  });

  useEffect(() => {
    if (user) {
      setFormData({
        nome: user.nome || '',
        cognome: user.cognome || '',
        email: user.email || '',
        password: '',
        role: user.role || 'user',
        isValidated: user.isValidated || false,
        isActive: user.isActive || false
      });
    } else {
      setFormData({
        nome: '',
        cognome: '',
        email: '',
        password: '',
        role: 'user',
        isValidated: false,
        isActive: false
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = { ...formData };
    
    if (dataToSubmit.password === '') {
      delete dataToSubmit.password;
    }

    if (user && user._id) {
      dataToSubmit._id = user._id;
    }

    onSubmit(dataToSubmit);
  };

  return (
    <form onSubmit={handleSubmit} className="user-form">
      <div className="form-row">
        <div className="form-column">
          <div className="form-group">
            <label htmlFor="nome">Nome:</label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-column">
          <div className="form-group">
            <label htmlFor="cognome">Cognome:</label>
            <input
              type="text"
              id="cognome"
              name="cognome"
              value={formData.cognome}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-row">
        <div className="form-column">
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required={!user}
            />
          </div>
        </div>
        <div className="form-column">
          <div className="form-group">
            <label htmlFor="role">Ruolo:</label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
            >
              <option value="user">Utente</option>
              <option value="admin">Amministratore</option>
            </select>
          </div>
        </div>
      </div>
      {showValidationFields && (
        <div className="validation-fields">
          <h3>Stato dell'account</h3>
          <div className="form-group checkbox">
            <input
              type="checkbox"
              id="isValidated"
              name="isValidated"
              checked={formData.isValidated}
              onChange={handleChange}
            />
            <label htmlFor="isValidated">Validato</label>
          </div>
          <div className="form-group checkbox">
            <input
              type="checkbox"
              id="isActive"
              name="isActive"
              checked={formData.isActive}
              onChange={handleChange}
            />
            <label htmlFor="isActive">Attivo</label>
          </div>
        </div>
      )}
      <div className="form-actions">
        <button type="submit" className="submit-button">
          {user ? 'Aggiorna Utente' : 'Crea Utente'}
        </button>
        <button type="button" onClick={onCancel} className="cancel-button">
          Annulla
        </button>
      </div>
    </form>
  );
};

export default UserForm;