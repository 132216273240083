import React, { useState, useEffect } from 'react';
import { FaEye, FaTrash, FaSearch, FaUpload } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { 
  listKnowledgeBaseDocuments, 
  uploadKnowledgeBaseDocument, 
  deleteKnowledgeBaseDocument 
} from '../../services/api';
import '../../styles/AdminDashboard.css';
import '../../styles/KnowledgeBaseManagement.css';

const KnowledgeBaseManager = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      setIsLoading(true);
      const response = await listKnowledgeBaseDocuments();
      setDocuments(response);
    } catch (error) {
      toast.error('Errore nel recupero dei documenti');
      console.error('Errore nel recupero dei documenti:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredDocuments = documents.filter(doc =>
    doc.originalName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    try {
      await uploadKnowledgeBaseDocument(file);
      toast.success('Documento caricato con successo');
      fetchDocuments();
      // Non resettiamo il file qui per mantenere il nome visualizzato
    } catch (error) {
      toast.error('Errore nel caricamento del documento');
      console.error('Errore nel caricamento del documento:', error);
    }
  };

  const handleDelete = async (documentId) => {
    if (window.confirm('Sei sicuro di voler eliminare questo documento?')) {
      try {
        await deleteKnowledgeBaseDocument(documentId);
        toast.success('Documento eliminato con successo');
        fetchDocuments();
      } catch (error) {
        toast.error('Errore nell\'eliminazione del documento');
        console.error('Errore nell\'eliminazione del documento:', error);
      }
    }
  };

  const handleViewDocument = (document) => {
    setSelectedDocument(document);
  };

  const handleCloseDocument = () => {
    setSelectedDocument(null);
  };

  const renderDocumentTable = () => (
    <table className="admin-table">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Tipo</th>
          <th>Numero di Chunk</th>
          <th>Data Creazione</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        {filteredDocuments.map((doc) => (
          <tr key={doc._id}>
            <td>{doc.originalName}</td>
            <td>{doc.mimeType}</td>
            <td>{doc.chunkCount}</td>
            <td>{new Date(doc.createdAt).toLocaleDateString()}</td>
            <td>
              <button onClick={() => handleViewDocument(doc)} className="action-button">
                <FaEye />
              </button>
              <button onClick={() => handleDelete(doc._id)} className="action-button delete">
                <FaTrash />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderDocumentViewer = () => (
    <div className="document-viewer">
      <h3>{selectedDocument.originalName}</h3>
      <p>ID: {selectedDocument._id}</p>
      <p>Creato il: {new Date(selectedDocument.createdAt).toLocaleString()}</p>
      <p>Tipo: {selectedDocument.mimeType}</p>
      <p>Numero di Chunk: {selectedDocument.chunkCount}</p>
      {selectedDocument.mimeType === 'application/pdf' ? (
        <iframe
          src={selectedDocument.s3Url}
          width="100%"
          height="500px"
          title="PDF Viewer"
        />
      ) : (
        <p>Anteprima non disponibile per questo tipo di file.</p>
      )}      
      <button onClick={handleCloseDocument} className="close-button">
        Chiudi
      </button>
    </div>
  );

  return (
    <div className="knowledge-base-manager">
      <h2 className="content-title">Gestione Knowledge Base</h2>
      {!selectedDocument && (
        <div className="kb-actions">
          <div className="kb-search">
            <FaSearch />
            <input
              type="text"
              placeholder="Cerca documento..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="kb-upload">
            <input
              type="file"
              onChange={handleFileChange}
              id="file-upload"
              className="file-input"
            />
            <label htmlFor="file-upload" className="file-label">
              {file ? file.name : 'Scegli file'}
            </label>
            <button onClick={handleUpload} className="upload-button" disabled={!file}>
              <FaUpload /> Carica
            </button>
          </div>
        </div>
      )}
      {isLoading ? (
        <p>Caricamento...</p>
      ) : selectedDocument ? (
        renderDocumentViewer()
      ) : (
        renderDocumentTable()
      )}
    </div>
  );
};

export default KnowledgeBaseManager;